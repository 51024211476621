import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
// import {useLayout} from '../../core'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const serverUrl = process.env.REACT_APP_SERVER_URL

const Navbar = () => {
  // const {config} = useLayout();
  const {currentUser} = useAuth();
  let loggedInUser = JSON.parse(`${localStorage.getItem('user')}`);
  let photo2 = loggedInUser.photo;
  var photo = `${serverUrl}/assets/users/${currentUser?.id}/profile/${photo2}`;
  return (
    <div className='app-navbar flex-shrink-0'>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {
            currentUser?.photo !== null ?
            <img alt='Logo' src={photo} />
            : <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          }
        </div>
        <HeaderUserMenu />
      </div>

    </div>
  )
}

export {Navbar}
