/* eslint-disable react/jsx-no-target-blank */
// import React from 'react'
import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.PAGES'})}</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.GROUPS'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/communication/com014.svg'
      >
        <SidebarMenuItem to='/groups/all' title={intl.formatMessage({id: 'MENU.VIEWGROUP'})} hasBullet={true} />
        <SidebarMenuItem to='/groups/add' title={intl.formatMessage({id: 'MENU.ADDGROUP'})} hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.CUSTOMFIELDS'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItem to='/fields/all' title={intl.formatMessage({id: 'MENU.VIEWCUSTOMFIELDS'})} hasBullet={true} />
        <SidebarMenuItem to='/fields/add' title={intl.formatMessage({id: 'MENU.ADDCUSTOMFIELD'})} hasBullet={true} />
      </SidebarMenuItemWithSub>
      
      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.CONTACTS'})}
        fontIcon='bi-person'
        icon='/media/icons/duotune/communication/com005.svg'
      >
        <SidebarMenuItem to='/contacts/all' title={intl.formatMessage({id: 'MENU.VIEWCONTACTS'})} hasBullet={true} />
        <SidebarMenuItem to='/contacts/add' title={intl.formatMessage({id: 'MENU.ADDCONTACT'})} hasBullet={true} />
        <SidebarMenuItem to='#' title={intl.formatMessage({id: 'MENU.IMPORTCONTACTS'})} hasBullet={true} />
      </SidebarMenuItemWithSub>
      
      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.BROADCASTS'})}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com003.svg'
      >
        <SidebarMenuItem to='#' title={intl.formatMessage({id: 'MENU.VIEWBROADCASTS'})} hasBullet={true} />
        <SidebarMenuItem to='#' title={intl.formatMessage({id: 'MENU.ADDBROADCAST'})} hasBullet={true} />
      </SidebarMenuItemWithSub>
      
      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.CONVERSATIONS'})}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen019.svg'
      >
        <SidebarMenuItem to='#' title={intl.formatMessage({id: 'MENU.VIEWCONVERSATIONS'})} hasBullet={true} />
        <SidebarMenuItem to='#' title={intl.formatMessage({id: 'MENU.SENDMESSAGES'})} hasBullet={true} />
        <SidebarMenuItem to='#' title={intl.formatMessage({id: 'MENU.SENDCAMPAIGNS'})} hasBullet={true} />
      </SidebarMenuItemWithSub>
      
      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.TEMPLATES'})}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/layouts/lay008.svg'
      >
        <SidebarMenuItem to='#' title={intl.formatMessage({id: 'MENU.VIEWTEMPLATES'})} hasBullet={true} />
        <SidebarMenuItem to='#' title={intl.formatMessage({id: 'MENU.ADDTEMPLATES'})} hasBullet={true} />
      </SidebarMenuItemWithSub>
      
      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.ANALYTICS'})}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen032.svg'
      >
        <SidebarMenuItem to='#' title={intl.formatMessage({id: 'MENU.VIEWSTATS'})} hasBullet={true} />
        <SidebarMenuItem to='#' title={intl.formatMessage({id: 'MENU.EXPORTSTATS'})} hasBullet={true} />
      </SidebarMenuItemWithSub>
      
      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.LOGS'})}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/abstract/abs027.svg'
      >
        <SidebarMenuItem to='#' title={intl.formatMessage({id: 'MENU.ACTIVITYLOGS'})} hasBullet={true} />
        <SidebarMenuItem to='#' title={intl.formatMessage({id: 'MENU.LOGINLOGS'})} hasBullet={true} />
      </SidebarMenuItemWithSub>
      
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
        hide="menu-hide"
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
        hide="menu-hide"
      />
    </>
  )
}

export {SidebarMenuMain}
